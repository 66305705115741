import { mapState } from 'vuex'

const mapStyle = {

  data () {
    return {
      currentMapStyle: this.$store.state.common.mapStyle,
      isFirstMapStyle: true,
      satelliteLayer: undefined
    }
  },

  computed: {
    ...mapState({
      mapStyle (state) {
        this.$nextTick(() => {
          if (!this.isFirstMapStyle) {
            this.currentMapStyle = state.common.mapStyle
            this.setMapStyle()
          } else {
            this.isFirstMapStyle = false
          }
        })
        return state.common.mapStyle
      }
    })
  },
  methods: {
    /**
     * 地图主题改变
     */
    setMapStyle () {
      if (this.map) {
        if (this.currentMapStyle === 'satellite') {
          this.map.add([this.getSatelliteLayer()])
        } else {
          this.map.remove([this.getSatelliteLayer()])
          this.map.setMapStyle(this.getMapStyle())
        }
        this.setMapFeatures()
      }
    },
    getMapStyle () {
      if (this.currentMapStyle === 'satellite') {
        return 'amap://styles/normal'
      }
      return 'amap://styles/' + this.currentMapStyle
    },
    setMapFeatures () {
      if (this.currentMapStyle  ===  '8ea104d16c14b242522f70fbda71927c') {
        this.map.setFeatures(['bg', 'road', 'building'])
      } else {
        this.map.setFeatures(['bg', 'road', 'building', 'point'])
      }
    },
    /**
     * 构建地图其他layer 例如卫星图
     */
    buildMapOtherLayer () {
      if (this.map && this.currentMapStyle === 'satellite') {
        this.map.add([this.getSatelliteLayer()])
      }
    },

    getSatelliteLayer () {
      if (!this.satelliteLayer) {
        this.satelliteLayer = new AMap.TileLayer.Satellite()
      }
      return this.satelliteLayer
    }
  }
}

export default mapStyle