<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">任务登记统计</h1>
    </div>
    <a-card :bordered="false" class="m-t-24">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper m-b-none">
          <a-form layout="inline">
            <a-form-item label="上报月份">
              <a-month-picker :allowClear="false" :locale="locale"
                              placeholder="请选择月份"
                              @change="dateChange"
                              :defaultValue="moment(currentMonth, 'YYYY-MM')"/>
            </a-form-item>
            <a-form-item label="集污点类型">
              <a-select default-value="ALL"
                        style="width: 130px"
                        @change="selectSpotType"
                        v-model="searchParams.spotType">
                <a-select-option value="ALL">全部</a-select-option>
                <a-select-option
                  v-for="item in spotTypeList"
                  :key="item.value"
                  :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="exportReport" icon="export" :loading="exportLoading">导出</a-button>
            </a-form-item>
            <a-form-item label="区域选择">
              <area-selection @onclickArea="onclickArea" @search="search"></area-selection>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-card>
    <a-card :bordered="false" class="m-t-24">
      <a-table size="middle"
               bordered :loading="tableLoading"
               :rowKey="(record) => record.id"
               @change="tableChange"
               :pagination="pagination"
               :columns="initColumns"
               :dataSource="tableData"
               :customRow="handleView">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="reason" slot-scope="text, record">
          {{ record.fromAddress }}{{ record.reason }}
        </template>
      </a-table>
    </a-card>
    <a-drawer title="任务登记详情" width="800px"
              placement="right" :mask="false" :closable="true"
              @close="detailClose"
              :visible="detailDrawer">
      <report-detail ref="viewRef"></report-detail>
    </a-drawer>
  </page-layout>
</template>

<script>
import SERVICE_URLS from '@/api/service.url'
import { statisticsColumns } from '../common/common'
import PageLayout from '@/components/page/PageLayout'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import moment from 'moment'
import VueCookie from 'vue-cookie'
import '../../../../plugins/fileDownload/download.min'
import { DEFAULT_X_AUTH_TOKEN } from '../../../../store/mutation-types'
import ReportDetail from '../../reportrecord/report/ReportDetail'
import AreaSelection from '../../statistics/loophistory/AreaSelection'
import { spotTypeList } from '@/constant/spot/spotType'

export default {
  name: 'ReportRecordMonthStatistics',
  components: { PageLayout, ReportDetail, AreaSelection },
  data () {
    return {
      moment,
      locale,
      detailDrawer: false,
      exportLoading: false,
      searchParams: {
        number: '0',
        size: '10',
        direction: '',
        order: '',
        reportDate: moment().format('YYYY-MM'),
        orgId: null,
        codes: '',
        spotType: 'ALL'
      },
      initColumns: statisticsColumns(),
      tableData: [],
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}条记录`
      },
      currentMonth: moment().format('YYYY-MM'),
      tableLoading: false,
      deptList: [],
      spotTypeList: spotTypeList()
    }
  },
  created () {
    this.loadDept()
    this.search()
  },
  methods: {
    loadDept () {
      this.$http(this, {
        url: SERVICE_URLS.organization.antTree,
        noTips: true,
        success: (data) => {
          this.deptList = data.body
        }
      })
    },
    selectSpotType () {
      this.search()
    },
    search () {
      this.restPage()
      this.loadTableData()
    },
    loadTableData () {
      this.tableLoading = true
      this.$http(this, {
        url: SERVICE_URLS.csgz.reportRecord.report_statistics,
        noTips: true,
        loading: 'tableLoading',
        data: this.searchParams,
        success: (data) => {
          this.tableData = data.body.content
          this.pagination.total = data.body.totalElements
          this.pagination.current = data.body.number + 1
          this.pagination.pageSize = data.body.size
          this.searchParams.size = data.body.size
          this.searchParams.number = data.body.number
          this.tableLoading = false
        }
      })
    },
    restPage () {
      this.searchParams.number = 0
    },
    /*表格页数发生变化*/
    tableChange (pagination, filters, sorter) {
      this.setSearchParamsPage(pagination)
      this.loadTableData()
    },
    setSearchParamsPage (pagination) {
      this.searchParams.number = pagination.current - 1
      this.searchParams.size = pagination.pageSize
    },
    dateChange (date, dateString) {
      this.searchParams.reportDate = dateString
      this.search()
    },
    detailClose () {
      this.detailDrawer = false
    },
    handleView (record) {
      return {
        on: {
          click: () => {
            this.detailDrawer = true
            this.$nextTick(() => {
              this.$refs.viewRef.loadData(record.id)
            })
          }
        }
      }
    },
    exportReport () {
      this.$http(this, {
        url: SERVICE_URLS.csgz.reportRecord.export,
        noTips: true,
        loading: 'exportLoading',
        data: this.searchParams,
        success: (data) => {
          let downUrl = SERVICE_URLS.document.download.path.replace('{id}', data.body.id) + '?attname=' + encodeURIComponent(data.body.name)
          const xAuthToken = VueCookie.get(DEFAULT_X_AUTH_TOKEN)
          if (!xAuthToken) {
            this.$Message.error({
              closable: true,
              content: '下载凭证丢失，请重新登录！',
              duration: 3
            })
            return
          }
          downUrl += '&' + DEFAULT_X_AUTH_TOKEN + '=' + xAuthToken
          // eslint-disable-next-line
          downloadFile(downUrl)
        }
      })
    },
    onclickArea (areaCodes) {
      this.searchParams.codes = areaCodes.join(',')
    }
  }
}
</script>
